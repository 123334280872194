<template>
  <div class="home">
    <el-container>
      <!-- 侧边栏区域 -->

      <el-aside :width="isCollapse ? '64px' : '240px'">

        <!-- 侧边栏次菜单区域 -->
        <!-- unique-opened一次只能打开一个 -->
        <el-row>

          <el-col :span="12">
            <div>
              <span>甘谷县物流管理系统</span>
            </div>
            <el-menu background-color="#171E49" text-color="#fff" active-text-color="#ffd04b" unique-opened
              :collapse="isCollapse" router>
              <!-- 一级菜单 -->
              <div>
                <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id"
                  v-if="item.hasMenu == true && item.menuState == 1">
                  <!-- 一级菜单模板区 -->
                  <template slot="title">
                    <!-- 图标 -->
                    <i :class="iconObj[item.id]"></i>
                    <!-- 文本 -->
                    <span>{{ item.menuName }}</span>
                  </template>
                  <!-- 二级菜单 -->
                  <template v-for="(i, index) in item.subMenu" v-if="i.hasMenu == true && i.menuState == 1">
                    <!-- 包含三级菜单 -->
                    <el-submenu v-if="i.subMenu.length" :index="'/' + i.menuUrl" :key="i.id">
                      <template slot="title">
                        <i class="el-icon-menu"></i>
                        <span>{{ i.menuName }}</span>
                      </template>
                      <el-menu-item icon="el-icon-menu" v-for="j in i.subMenu" :key="j.id" :index="'/' + j.menuUrl"
                        style="padding-left:90px">
                        <i class="el-icon-s-check"></i>
                        <span>{{ j.menuName }}</span>
                      </el-menu-item>
                    </el-submenu>
                    <!-- 不包含三级菜单 -->
                    <el-menu-item v-else :index="'/' + i.menuUrl" style="padding-right:130px">
                      <i class="el-icon-menu"></i>
                      <span>{{ i.menuName }}</span>
                    </el-menu-item>
                  </template>
                </el-submenu>
              </div>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>

      <!-- 页面主体区域 -->
      <el-container>
        <!-- 头部区域 -->
        <el-header>
          <el-button type="info" class="signOut" @click="signOut()">退出</el-button>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
// import employeeVue from '../components/welcome/employee.vue'
// import privilegeVue from '../components/welcome/privilege.vue'

// import Router from '../router/index'
export default {
  data() {
    return {
      menuList: [],
      iconObj: {
        31: 'el-icon-truck',
        1: 'el-icon-setting',
        6: 'el-icon-s-custom',
        8: 'el-icon-box',
        11: 'el-icon-map-location',
        13: 'el-icon-truck',
      },
      isCollapse: false,
    }
  },
  created() {
    // console.log(this.$router)
    this.getMenuList()
    // if (this.$router.path !== '/welcome') {
    //   this.$router.replace('/welcome')
    // }
  },
  methods: {
    signOut() {
      //   window.sessionStorage.clear()
      this.axios.get('/api/site/login/logout').then((res) => {
        // 清空token
        window.sessionStorage.clear()
        // 转向登录页
        this.$router.push('/')
      })
    },
    getMenuList() {
      this.axios.get('/api/site/menu/getMenuRights').then((res) => {
        // console.log(res)
        this.menuList = res.data.data
      })
    },
  },
}
</script>
<style lang="less" scoped>
.home {
  height: 100%;

  .el-container {
    height: 100%;
  }
}

.el-header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: end;
}

.el-aside {
  background-color: #171e49;

  div {
    margin-bottom: 44px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #fff;
  }

  .el-row {
    margin-top: 44px;
  }

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #f3f4f9;
}

.el-submenu__title {
  i {
    float: left;
    line-height: 56px;
    color: #fff;
  }

  span {
    float: left;
  }
}
</style>
